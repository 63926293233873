import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { db } from "../../user/firebaseConfig";
import { collection, getDocs } from 'firebase/firestore';
import "./company-section.css";

const CompanySection = () => {
  const [texts, setTexts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'adds'));
      const arabicTexts = querySnapshot.docs.map(doc => doc.data().text);
      setTexts(arabicTexts);
    };

    fetchData();
  }, []);

  return (
    <section className="company-section">
      <Container>
        <div className="text-row">
          {texts.map((text, index) => (
            <h3 className="text-item" key={index}>
              {text}
            </h3>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default CompanySection;