import React from "react";
import ChooseUs from "../components/choose-us/ChooseUs";
import Features from "../components/features/Features";
import Footer from "../components/footer/Footer";
import HeroSection from "../components/hero-section/HeroSection";
import CompanySection from "../components/company-section/CompanySection";



const Home = () => {


  return (
    <>
     <CompanySection />
      <HeroSection />
     
      <ChooseUs />
  
      <Features />

      
      <Footer />
    </>
  );
};

export default Home;
