import React, { useEffect, useState } from "react";
import { db } from "../FB/firebaseConfig"; // Adjust the path if necessary
import { collection, getDocs, deleteDoc, doc, where, query } from "firebase/firestore";

const RemoveEnrolledUser = () => {
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortAscending, setSortAscending] = useState(true); // State for sorting order

  useEffect(() => {
    const fetchEnrolledUsers = async () => {
      const enrollmentsRef = collection(db, "enrollments");
      const enrollmentSnapshot = await getDocs(enrollmentsRef);
      const enrolledData = await Promise.all(
        enrollmentSnapshot.docs.map(async (doc) => {
          const enrollment = doc.data();
          const userQuery = query(collection(db, "users"), where("uid", "==", enrollment.userId));
          const userSnapshot = await getDocs(userQuery);
          const userData = userSnapshot.docs.length > 0 ? userSnapshot.docs[0].data() : null;

          return {
            id: doc.id,
            userId: enrollment.userId,
            courseName: enrollment.courseName,
            userName: userData ? `${userData.firstName} ${userData.lastName}` : "Unknown User",
            email: userData ? userData.email : "N/A",
            addedDate: new Date(enrollment.timestamp.seconds * 1000).toLocaleDateString(),
          };
        })
      );

      setEnrolledUsers(enrolledData);
    };

    fetchEnrolledUsers();
  }, []);

  const handleDelete = async (id, userName) => {
    const confirmDelete = window.confirm(`Are you sure you want to remove ${userName}?`);
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "enrollments", id));
        setEnrolledUsers(prevUsers => prevUsers.filter(user => user.id !== id));
        alert("User removed successfully.");
      } catch (error) {
        console.error("Error removing user:", error);
        alert("Error removing user.");
      }
    }
  };

  // Filter users based on the search term
  const filteredUsers = enrolledUsers.filter(user =>
    user.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort users by course name
  const sortedUsers = filteredUsers.sort((a, b) => {
    if (a.courseName < b.courseName) return sortAscending ? -1 : 1;
    if (a.courseName > b.courseName) return sortAscending ? 1 : -1;
    return 0;
  });

  const toggleSortOrder = () => {
    setSortAscending(!sortAscending);
  };

  return (
    <div>
      <h2>Remove Enrolled Users</h2>
      <input
        type="text"
        placeholder="Search by name or email..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
      <button onClick={toggleSortOrder}>
        Sort by Course Name {sortAscending ? '▲' : '▼'}
      </button>
      {sortedUsers.length > 0 ? (
        <table className="enrolled-users-table">
          <thead>
            <tr>
              <th>User Name</th>
              <th>Email</th>
              <th>Course Name</th>
              <th>Date Added</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sortedUsers.map(user => (
              <tr key={user.id}>
                <td>{user.userName}</td>
                <td>{user.email}</td>
                <td>{user.courseName}</td>
                <td>{user.addedDate}</td>
                <td>
                  <button onClick={() => handleDelete(user.id, user.userName)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No enrolled users found.</p>
      )}
    </div>
  );
};

export default RemoveEnrolledUser;