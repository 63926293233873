import React, { useState, useEffect } from "react";
import { db } from "../FB/firebaseConfig.js";
import { collection, getDocs, addDoc, deleteDoc, doc, writeBatch } from "firebase/firestore";
import "./AddHomework.css"; // Import your custom CSS

const AddHomework = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(""); 
  const [questions, setQuestions] = useState([{ id: Date.now(), question: "", options: ["", "", "", ""], correctOption: 0 }]);
  const [homeworks, setHomeworks] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const courseCollection = collection(db, "courses");
      const courseSnapshot = await getDocs(courseCollection);
      const courseData = courseSnapshot.docs.map(doc => doc.data().name);
      setCourses(courseData);
    };

    fetchCourses();
    fetchHomeworks();
  }, []);

  const fetchHomeworks = async () => {
    const homeworkCollection = collection(db, "homeworks");
    const homeworkSnapshot = await getDocs(homeworkCollection);
    const homeworkData = homeworkSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setHomeworks(homeworkData);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    if (questions.length < 10) {
      setQuestions([...questions, { id: Date.now(), question: "", options: ["", "", "", ""], correctOption: 0 }]);
    }
  };

  const handleCorrectOptionChange = (questionIndex, index) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].correctOption = index;
    setQuestions(newQuestions);
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCourse) {
        const batch = writeBatch(db);
        const questionRefs = [];

        questions.forEach(async (q) => {
          const questionRef = doc(collection(db, "homeworkqu"));
          batch.set(questionRef, {
            question: q.question,
            options: q.options,
            correctOption: q.correctOption
          });
          questionRefs.push(questionRef);
        });

        const homeworkRef = await addDoc(collection(db, "homeworks"), {
          course: selectedCourse,
          questions: questionRefs
        });

        await batch.commit();

        console.log("Homework submitted successfully!");
        setQuestions([{ id: Date.now(), question: "", options: ["", "", "", ""], correctOption: 0 }]);
        setSelectedCourse("");
        fetchHomeworks();
      }
    } catch (error) {
      console.error("Error saving homework:", error);
    }
  };

  const handleDeleteHomework = async (id) => {
    try {
      await deleteDoc(doc(db, "homeworks", id));
      setHomeworks(homeworks.filter(hw => hw.id !== id));
      console.log("Homework deleted successfully!");
    } catch (error) {
      console.error("Error deleting homework:", error);
    }
  };

  return (
    <div className="add-homework-container">
      <form onSubmit={handleSubmit} className="add-homework-form">
        <h3 className="form-title">Add Homework</h3>
        <div className="form-group">
          <label>Select Course:</label>
          <select value={selectedCourse} onChange={handleCourseChange} required>
            <option value="">Select Course</option>
            <option value="All">All Courses</option>
            {courses.map(course => (
              <option key={course} value={course}>{course}</option>
            ))}
          </select>
        </div>

        {questions.map((q, questionIndex) => (
          <div key={q.id} className="question-block">
            <div className="form-group">
              <label>Question:</label>
              <input
                type="text"
                value={q.question}
                onChange={(e) => handleQuestionChange(questionIndex, e.target.value)}
                required
              />
            </div>
            {q.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group">
                <label>Option {optionIndex + 1}:</label>
                <input
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(questionIndex, optionIndex, e.target.value)}
                  required
                />
                <div className="radio-buttons">
                  <label className={`radio-label ${q.correctOption === optionIndex ? 'selected' : ''}`}>
                    <input
                      type="radio"
                      name={`correctOption-${questionIndex}`} // Unique name based on question index
                      value={optionIndex}
                      checked={q.correctOption === optionIndex}
                      onChange={() => handleCorrectOptionChange(questionIndex, optionIndex)}
                    />
                    Mark as Correct
                  </label>
                </div>
              </div>
            ))}
          </div>
        ))}

        <button type="button" onClick={addQuestion} disabled={questions.length >= 10}>
          Add Question
        </button>
        <button type="submit">Submit Homework</button>
      </form>

      <h3>Existing Homeworks</h3>
      <ul className="homework-list">
        {homeworks.map(hw => (
          <li key={hw.id} className="homework-item">
            <span>{hw.questions.map(q => q.question).join(", ")} (Course: {hw.course})</span>
            <button onClick={() => handleDeleteHomework(hw.id)} className="btn-delete">Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddHomework;