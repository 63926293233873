import React, { useState, useEffect } from "react";
import { db } from "../FB/firebaseConfig.js";
import { collection, addDoc, deleteDoc, doc, getDocs } from "firebase/firestore";

const ManageExams = ({ courses }) => {
  const [selectedCourse, setSelectedCourse] = useState("");
  const [examTitle, setExamTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [exams, setExams] = useState([]);

  // Function to fetch exams from Firestore
  const fetchExams = async () => {
    const examsRef = collection(db, "exams");
    const examDocs = await getDocs(examsRef);
    setExams(examDocs.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  // Fetch exams on component mount
  useEffect(() => {
    fetchExams();
  }, []);

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: "", options: ["", "", "", ""], correctOption: "" }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleCorrectOptionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].correctOption = newQuestions[index].options[value]; // Store the actual text of the correct option
    setQuestions(newQuestions);
  };

  const handleAddExam = async (e) => {
    e.preventDefault();
    setLoading(true);
    const examsRef = collection(db, "exams");

    try {
      // Handle adding to all courses
      if (selectedCourse === "All") {
        for (const course of courses) {
          const examDoc = await addDoc(examsRef, { 
            title: examTitle, 
            courseName: course.name 
          });
          const examId = examDoc.id;

          // Handle questions for the exam in all courses
          for (const question of questions) {
            await addDoc(collection(db, "questions"), {
              examId,
              question: question.question,
              options: question.options,
              correctOption: question.correctOption,
              courseName: course.name,
              title: examTitle // Include the exam title with each question
            });
          }
        }
      } else {
        // Create the exam document with title and selected course name
        const examDoc = await addDoc(examsRef, { 
          title: examTitle, 
          courseName: selectedCourse 
        });
        const examId = examDoc.id;

        // Handle questions for the exam in the selected course
        for (const question of questions) {
          await addDoc(collection(db, "questions"), {
            examId,
            question: question.question,
            options: question.options,
            correctOption: question.correctOption,
            courseName: selectedCourse,
            title: examTitle // Include the exam title with each question
          });
        }
      }

      setSuccessMessage("Exam and questions added successfully!");
      setExamTitle("");
      setQuestions([]);
      fetchExams(); // Refresh the exam list
    } catch (error) {
      console.error("Error adding exam:", error);
      setSuccessMessage("Error adding exam. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteExam = async (examId) => {
    const examRef = doc(db, "exams", examId);
    try {
      await deleteDoc(examRef);
      setSuccessMessage("Exam deleted successfully!");
      fetchExams(); // Refresh the exam list
    } catch (error) {
      console.error("Error deleting exam:", error);
      setSuccessMessage("Error deleting exam. Please try again.");
    }
  };

  return (
    <div>
      <div className="form-group">
        <label htmlFor="courseSelect">Select Course</label>
        <select
          id="courseSelect"
          value={selectedCourse}
          onChange={(e) => setSelectedCourse(e.target.value)}
          required
          className="styled-select"
        >
          <option value="">Select Course</option>
          <option value="All">All Courses</option>
          {courses.map(course => (
            <option key={course.name} value={course.name}>{course.name}</option>
          ))}
        </select>
      </div>

      <form onSubmit={handleAddExam} className="manage-course-form">
        <div className="form-group">
          <label htmlFor="examTitle">Exam Title</label>
          <input
            type="text"
            id="examTitle"
            value={examTitle}
            onChange={(e) => setExamTitle(e.target.value)}
            required
            className="styled-input"
          />
        </div>

        {questions.map((q, index) => (
          <div key={index} className="question-block">
            <div className="form-group">
              <label htmlFor={`question_${index}`}>Question</label>
              <input
                type="text"
                id={`question_${index}`}
                value={q.question}
                onChange={(e) => handleQuestionChange(index, e.target.value)}
                required
                className="styled-input"
              />
            </div>

            {q.options.map((option, optionIndex) => (
              <div key={optionIndex} className="form-group">
                <label htmlFor={`option_${index}_${optionIndex}`}>Option {optionIndex + 1}</label>
                <input
                  type="text"
                  id={`option_${index}_${optionIndex}`}
                  value={option}
                  onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                  required
                  className="styled-input"
                />
              </div>
            ))}

            <div className="form-group">
              <label htmlFor={`correctOption_${index}`}>Correct Option</label>
              <select
                id={`correctOption_${index}`}
                onChange={(e) => handleCorrectOptionChange(index, e.target.value)}
                required
                className="styled-select"
              >
                <option value="">Select Correct Option</option>
                {q.options.map((option, optionIndex) => (
                  <option key={optionIndex} value={optionIndex}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}

        <button type="button" className="btn-submit" onClick={handleAddQuestion}>
          Add Question
        </button>

        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? "Adding..." : "Add Exam and Questions"}
        </button>
      </form>

      <h3>Existing Exams</h3>
      <ul>
        {exams.map((exam) => (
          <li key={exam.id}>
            {exam.title} 
            <button onClick={() => handleDeleteExam(exam.id)} className="btn-delete">
              Delete
            </button>
          </li>
        ))}
      </ul>

      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default ManageExams;
