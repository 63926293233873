import React, { useEffect, useState } from "react";
import { db } from "../FB/firebaseConfig.js"; // Adjust the path if necessary
import { collection, getDocs, addDoc, deleteDoc, doc } from "firebase/firestore";
import './manage-adds.css'; // Import the CSS file

const ManageAdds = () => {
  const [adds, setAdds] = useState([]);
  const [newAdd, setNewAdd] = useState("");

  useEffect(() => {
    const fetchAdds = async () => {
      const addsCollection = collection(db, "adds");
      const addsSnapshot = await getDocs(addsCollection);
      const addsData = addsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAdds(addsData);
    };

    fetchAdds();
  }, []);

  const handleAdd = async () => {
    if (newAdd.trim() === "") return;

    try {
      await addDoc(collection(db, "adds"), { text: newAdd });
      setNewAdd("");
      const addsCollection = collection(db, "adds");
      const addsSnapshot = await getDocs(addsCollection);
      const addsData = addsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAdds(addsData);
    } catch (error) {
      console.error("Error adding add:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "adds", id));
      setAdds(adds.filter(add => add.id !== id));
    } catch (error) {
      console.error("Error deleting add:", error);
    }
  };

  return (
    <div className="manage-adds">
      <h2>إدارة الإعلانات</h2>
      <input
        type="text"
        value={newAdd}
        onChange={(e) => setNewAdd(e.target.value)}
        placeholder="أضف نصًا جديدًا"
        dir="rtl" // Ensure text input is right-to-left
      />
      <button onClick={handleAdd}>إضافة</button>

      <ul>
        {adds.map(add => (
          <li key={add.id}>
            {add.text}
            <button onClick={() => handleDelete(add.id)}>حذف</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ManageAdds;