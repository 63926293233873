import React, { useEffect, useState } from 'react';
import { db, storage } from "../FB/firebaseConfig";
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { ref, listAll, getDownloadURL, deleteObject, getMetadata } from 'firebase/storage';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './CourseVideos.module.css'; // Import your CSS styles

const ItemType = {
  VIDEO: 'video',
};

const DraggableVideoItem = ({ video, index, moveVideo, handleDeleteVideo, handleVideoClick }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.VIDEO,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <li
      ref={drag}
      className={`${styles.videoItem} ${isDragging ? styles.dragging : ''}`}
    >
      <span className={styles.videoName} onClick={() => handleVideoClick(video)}>
        {video.name}
      </span>
      <button
        className={styles.deleteButton}
        onClick={() => handleDeleteVideo(video)}
      >
        Delete
      </button>
      <span className={styles.videoDate}>{new Date(video.createdAt).toLocaleDateString()}</span>
    </li>
  );
};

const CourseVideos = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesRef = collection(db, "courses");
        const courseSnapshot = await getDocs(coursesRef);
        const courseData = courseSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCourses(courseData);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchVideosFromStorage = async () => {
      if (selectedCourse) {
        try {
          const videosRef = ref(storage, `courses/${selectedCourse.name}/videos`);
          const videoList = await listAll(videosRef);
          const videoData = await Promise.all(
            videoList.items.map(async (video) => {
              const url = await getDownloadURL(video);
              const metadata = await getMetadata(video); // Get metadata

              return { 
                name: video.name, 
                url, 
                path: video.fullPath, 
                createdAt: metadata.timeCreated // Get creation time from metadata
              };
            })
          );

          // Sort videos by creation date in ascending order (earliest first)
          videoData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          setVideos(videoData);
        } catch (error) {
          console.error("Error fetching videos:", error);
        }
      }
    };

    fetchVideosFromStorage();
  }, [selectedCourse]);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const handleDeleteVideo = async (video) => {
    const confirmDelete = window.confirm(`Are you sure you want to delete the video: ${video.name}?`);
    if (confirmDelete) {
      try {
        // Delete video from Firebase Storage
        const videoRef = ref(storage, video.path);
        await deleteObject(videoRef);

        // Delete from Firestore
        const videoDocRef = doc(db, "courses", selectedCourse.id, "videos", video.name);
        await deleteDoc(videoDocRef);

        // Update state to remove deleted video
        setVideos((prevVideos) => prevVideos.filter(v => v.name !== video.name));
      } catch (error) {
        console.error("Error deleting video:", error);
      }
    }
  };

  const moveVideo = (fromIndex, toIndex) => {
    const updatedVideos = [...videos];
    const [movedVideo] = updatedVideos.splice(fromIndex, 1);
    updatedVideos.splice(toIndex, 0, movedVideo);
    setVideos(updatedVideos);
  };

  // Filter courses based on the search term
  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <h2>Select Course to View Videos</h2>
        <input
          type="text"
          placeholder="Search courses..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchBar}
        />
        <select
          onChange={(e) => {
            const course = courses.find(course => course.id === e.target.value);
            setSelectedCourse(course);
            setVideos([]);
            setSelectedVideo(null);
          }}
          value={selectedCourse ? selectedCourse.id : ""}
        >
          <option value="">Select Course</option>
          {filteredCourses.map(course => (
            <option key={course.id} value={course.id}>{course.name}</option>
          ))}
        </select>

        {selectedCourse && (
          <div>
            <h3>Videos in {selectedCourse.name}</h3>
            <div className={styles.videoContainer}>
              {videos.length > 0 ? (
                <>
                  <div className={styles.mainVideoWrapper}>
                    {selectedVideo ? (
                      <video
                        key={selectedVideo.url}
                        controls
                        className={styles.mainVideo}
                      >
                        <source src={selectedVideo.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p>Select a video to play</p>
                    )}
                  </div>

                  <ul className={styles.videoList}>
                    {videos.map((video, index) => (
                      <DraggableVideoItem
                        key={index}
                        index={index}
                        video={video}
                        moveVideo={moveVideo}
                        handleDeleteVideo={handleDeleteVideo}
                        handleVideoClick={handleVideoClick}
                      />
                    ))}
                  </ul>
                </>
              ) : (
                <p>No videos available for this course.</p>
              )}
            </div>
          </div>
        )}
      </div>
    </DndProvider>
  );
};

export default CourseVideos;