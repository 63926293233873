import React, { useEffect, useState } from 'react';
import { db } from './firebaseConfig'; // Adjust the import as needed
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import styles from './Messages.module.css'; // Import your CSS module

const Messages = ({ userEmail }) => {
  const [messages, setMessages] = useState([]); // Messages sent by the user
  const [receivedMessages, setReceivedMessages] = useState([]); // Messages received by the user
  const [loading, setLoading] = useState(true); // State for loading
  const [messageContent, setMessageContent] = useState(''); // State for message input
  const [user, setUser] = useState(null); // State for user data

  const fetchUserData = async () => {
    try {
      const userQuery = query(collection(db, 'users'), where('email', '==', userEmail));
      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        setUser({
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        });
      } else {
        console.error('User not found');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchSentMessages = async () => {
    if (!user) {
      console.error('User information is not available.');
      return;
    }

    try {
      // Query the messages collection for messages sent by the user
      const messagesQuery = query(collection(db, 'messages'), where('email', '==', user.email));
      const messagesSnapshot = await getDocs(messagesQuery);
      const messagesData = messagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Keep all data
      setMessages(messagesData);
    } catch (error) {
      console.error('Error fetching sent messages:', error);
    }
  };

  const fetchReceivedMessages = async () => {
    if (!user) {
      console.error('User information is not available.');
      return;
    }

    try {
      // Query the userMessages collection for messages received by the user
      const userMessagesQuery = query(collection(db, 'userMessages'), where('email', '==', user.email));
      const userMessagesSnapshot = await getDocs(userMessagesQuery);
      const receivedMessagesData = userMessagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Keep all data
      setReceivedMessages(receivedMessagesData);
    } catch (error) {
      console.error('Error fetching received messages:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (userEmail) {
      fetchUserData();
    }
  }, [userEmail]);

  useEffect(() => {
    if (user) {
      fetchSentMessages();
      fetchReceivedMessages();
    }
  }, [user]); // Fetch messages whenever user changes

  const handleSendMessage = async (e) => {
    e.preventDefault(); // Prevent default form submission

    if (messageContent && user) {
      try {
        const userName = `${user.firstName || ''} ${user.lastName || ''}`.trim(); // Ensure user names are defined
        if (userName) {
          // Add a new message to the messages collection
          await addDoc(collection(db, 'messages'), {
            message: messageContent,
            name: userName, // Use user's name
            email: user.email, // Store the user's email in Firestore
          });
          setMessageContent(''); // Clear the message input
          // Optionally fetch sent messages again to update the list
          await fetchSentMessages();
        } else {
          console.error('User name is not defined.');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    } else {
      console.error('Message content or user information is missing.');
    }
  };

  return (
    <div className={styles.messagesContainer}>
      <h3>Your Messages</h3>
      <form onSubmit={handleSendMessage} className={styles.messageForm}>
        <textarea
          placeholder="Type your message here..."
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          required
          className={styles.messageInput}
        />
        <button type="submit" className={styles.sendButton}>Send Message</button>
      </form>
      {loading ? (
        <p>Loading messages...</p>
      ) : (
        <>
          <h4>Sent Messages</h4>
          {messages.length > 0 ? (
            <ul className={styles.messageList}>
              {messages.map(message => (
                <li key={message.id} className={styles.messageCard}>
                  <strong>{message.name}:</strong>
                  <p>{message.message}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No sent messages found.</p>
          )}
          
          <h4>Received Messages</h4>
          {receivedMessages.length > 0 ? (
            <ul className={styles.messageList}>
              {receivedMessages.map(message => (
                <li key={message.id} className={styles.messageCard}>
                  <strong>Support:</strong> {/* Display "Support" as the sender's name */}
                  <p>{message.message}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No received messages found.</p>
          )}
        </>
      )}
    </div>
  );
};

export default Messages;