import React, { useEffect, useState } from "react";
import { db } from "../FB/firebaseConfig";
import { collection, getDocs, query, where, addDoc, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Import Firebase Auth
import styles from './Homework.module.css';

const Homework = () => {
  const [homeworks, setHomeworks] = useState([]);
  const [selectedHomework, setSelectedHomework] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [grade, setGrade] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [timer, setTimer] = useState(600); // 10 minutes in seconds
  const [isTimeUp, setIsTimeUp] = useState(false);
  const auth = getAuth(); // Initialize Firebase Auth

  useEffect(() => {
    const user = auth.currentUser; // Get the current user
    if (user) {
      setUserEmail(user.email);
      checkEnrollment(user.email); // Check enrollment if user is logged in
    } else {
      console.error("No user is logged in.");
    }
  }, [auth]);

  const checkEnrollment = async (email) => {
    try {
      const enrollmentsRef = collection(db, "enrollments");
      const q = query(enrollmentsRef, where("email", "==", email));
      const enrollmentsSnapshot = await getDocs(q);

      if (!enrollmentsSnapshot.empty) {
        const courses = enrollmentsSnapshot.docs.map(doc => doc.data().courseName);
        setEnrolledCourses(courses);
        fetchHomeworks(courses); // Fetch homeworks based on enrolled courses
      } else {
        console.log("User is not enrolled in any courses.");
      }
    } catch (error) {
      console.error("Error checking enrollments: ", error);
    }
  };

  const fetchHomeworks = async (courses) => {
    try {
      const homeworksRef = collection(db, "homeworks");
      const homeworksSnapshot = await getDocs(homeworksRef);
      const homeworksData = await Promise.all(homeworksSnapshot.docs.map(async (doc) => {
        const homeworkData = doc.data();
        const homeworkQuestions = await Promise.all(
          homeworkData.questions.map(async (questionRef) => {
            const questionSnap = await getDoc(questionRef); // Get each question by reference
            const questionData = questionSnap.data();
            return {
              id: questionSnap.id,
              question: questionData.question,
              options: questionData.options,
              correctOption: questionData.correctOption,
            };
          })
        );
        return {
          id: doc.id,
          course: homeworkData.course,
          questions: homeworkQuestions,
        };
      }));
      
      setHomeworks(homeworksData);
    } catch (error) {
      console.error("Error fetching homeworks: ", error);
    }
  };

  const handleStartHomework = (homework) => {
    setSelectedHomework(homework);
    setUserAnswers({});
    setGrade(null);
    setSubmitted(false);
    setTimer(600); // Reset timer to 10 minutes
    setIsTimeUp(false); // Reset time up status
  };

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer, // Update the answer for the specific question ID
    }));
  };

  const handleSubmitHomework = async () => {
    if (!selectedHomework || !selectedHomework.questions) return;

    // Check if all questions have been answered
    const allAnswered = selectedHomework.questions.every((question) => userAnswers[question.id] !== undefined);
    if (!allAnswered) {
      alert("Please answer all questions before submitting.");
      return;
    }

    let score = 0;
    const results = selectedHomework.questions.map((question) => {
      const userAnswer = userAnswers[question.id]; // User's selected answer
      const correctAnswer = question.options[question.correctOption]; // Correct answer

      // Check if the answer is correct
      const isCorrect = userAnswer === correctAnswer;
      if (isCorrect) score++;
      
      return { question, isCorrect, userAnswer, correctAnswer };
    });

    const totalQuestions = selectedHomework.questions.length;
    const finalGrade = (totalQuestions > 0) ? (score / totalQuestions) * 100 : 0;
    setGrade(finalGrade);
    setSubmitted(true); // Set submitted state to true

    try {
      const gradesRef = collection(db, "userHomeworkGrades");
      await addDoc(gradesRef, {
        homeworkId: selectedHomework.id,
        homeworkTitle: selectedHomework.course,
        grade: finalGrade,
        timestamp: new Date(),
      });
    } catch (error) {
      console.error("Error saving grade: ", error);
    }

    // Store results with correctness information
    setSelectedHomework({
      ...selectedHomework,
      results,
    });
  };

  const handleBackToHomeworks = () => {
    setSelectedHomework(null);
    setUserAnswers({});
    setGrade(null);
    setSubmitted(false);
    setTimer(600); // Reset timer
    setIsTimeUp(false); // Reset time up status
  };

  useEffect(() => {
    let interval = null;

    if (selectedHomework && !submitted) {
      interval = setInterval(() => {
        setTimer(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval);
            setIsTimeUp(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [selectedHomework, submitted]);

  return (
    <div className={styles.homeworkContainer}>
      {submitted ? (
        <div>
          <h4>Your score: {grade !== null ? grade.toFixed(2) : "N/A"}%</h4>
          {selectedHomework?.results?.map(({ question, isCorrect }, index) => (
            <div key={index} style={{ color: isCorrect ? 'green' : 'red' }}>
              <h5>{question.question}</h5>
              {isCorrect ? (
                <p>Correct!</p>
              ) : (
                <p>Incorrect! Correct answer: {question.options[question.correctOption]}</p>
              )}
            </div>
          ))}
          {grade === 100 && <p style={{ color: 'green' }}>All questions are correct!</p>}
          {grade === 0 && <p style={{ color: 'red' }}>All questions are wrong!</p>}
        </div>
      ) : isTimeUp ? (
        <h4>Time is up! You can no longer access this homework.</h4>
      ) : selectedHomework ? (
        <div className={styles.homeworkWrapper}>
          <h4>{selectedHomework.course}</h4>
          {selectedHomework.questions && selectedHomework.questions.length > 0 ? (
            selectedHomework.questions.map((question) => (
              <div key={question.id}>
                <h5>{question.question}</h5>
                {question.options && question.options.length > 0 ? (
                  question.options.map((option, index) => (
                    <label key={index}>
                      <input
                        type="radio"
                        name={question.id} // Unique name for each question based on its ID
                        value={option}
                        checked={userAnswers[question.id] === option} // Check if the user's answer matches
                        onChange={() => handleAnswerChange(question.id, option)} // Update answer on change
                      />
                      {option}
                    </label>
                  ))
                ) : (
                  <p>No options available for this question.</p>
                )}
              </div>
            ))
          ) : (
            <p>No questions available for this homework.</p>
          )}
          <button className={styles.submitButton} onClick={handleSubmitHomework}>Submit Homework</button>
        </div>
      ) : (
        <ul className={styles.homeworkList}>
          {homeworks.map((homework) => (
            <li key={homework.id} className={styles.homeworkItem}>
              <h4>{homework.course}</h4>
              <button onClick={() => handleStartHomework(homework)}>Open</button>
            </li>
          ))}
        </ul>
      )}
      {selectedHomework && (
        <button className={styles.backButton} onClick={handleBackToHomeworks}>
          Back to Homeworks
        </button>
      )}
    </div>
  );
};

export default Homework;