import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { storage } from "./firebase"; // Ensure the correct path
import { ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { db } from "./firebaseConfig"; // Import your Firestore configuration
import { doc, getDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import styles from './CourseDetail.module.css'; // CSS for course details

const CourseDetailPage = () => {
  const { courseId } = useParams();
  const [course, setCourse] = useState(null);
  const [videos, setVideos] = useState([]);
  const [videoNames, setVideoNames] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const [videoEnded, setVideoEnded] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false); // Manage document visibility
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (!user) {
        navigate("/");
      } else {
        await fetchCourseData(courseId);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [auth, navigate, courseId]);

  const fetchCourseData = async (courseId) => {
    const courseRef = doc(db, "courses", courseId);
    const courseSnap = await getDoc(courseRef);
    if (courseSnap.exists()) {
      const courseData = courseSnap.data();
      setCourse(courseData);
      await fetchCourseResources(courseData.name);
    } else {
      console.log("No such course!");
      setLoading(false);
    }
  };

  const fetchCourseResources = async (courseName) => {
    try {
      console.log(`Fetching resources for course: ${courseName}`);
      const videoRef = ref(storage, `courses/${courseName}/videos`);
      const documentRef = ref(storage, `documents/${courseName}`); // Correct path

      // Fetch videos
      const videoList = await listAll(videoRef);
      const videoPromises = videoList.items.map(async (video) => {
        const url = await getDownloadURL(video);
        const metadata = await getMetadata(video);
        return { url, name: video.name, created: new Date(metadata.timeCreated) };
      });

      const videoData = await Promise.all(videoPromises);
      videoData.sort((a, b) => a.created - b.created);
      setVideos(videoData.map(video => video.url));
      setVideoNames(videoData.map(video => video.name));

      // Fetch documents
      const documentList = await listAll(documentRef);
      console.log("Document List:", documentList);

      const documentData = await Promise.all(
        documentList.items.map(async (doc) => {
          const url = await getDownloadURL(doc);
          return { url, name: doc.name }; // Include document name
        })
      );

      console.log("Fetched documents:", documentData);
      setDocuments(documentData); // Update state with document data
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  useEffect(() => {
    if (videos.length > 0) {
      setSelectedVideoIndex(0);
    }
  }, [videos]);

  const handleVideoClick = (index) => {
    setSelectedVideoIndex(index);
    setVideoEnded(false);
  };

  const handleVideoEnded = () => {
    setVideoEnded(true);
  };

  const handleNextVideo = () => {
    const nextIndex = (selectedVideoIndex + 1) % videos.length;
    setSelectedVideoIndex(nextIndex);
    setVideoEnded(false);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  if (loading) {
    return <p>Loading course details...</p>;
  }

  return (
    <div className={styles.courseDetail} onContextMenu={preventContextMenu}>
      <aside className={styles.sidebar}>
        <h2>{course ? course.name : "Course Not Found"}</h2>
        <button onClick={() => navigate("/profile")} className={styles.navButton}>Back to Profile</button>
        <button onClick={async () => { await auth.signOut(); navigate("/"); }} className={styles.navButton}>Logout</button>

        {/* Button to show documents */}
        <button onClick={() => setShowDocuments(!showDocuments)} className={styles.navButton}>
          {showDocuments ? "Hide Documents" : "Show Documents"}
        </button>

        {/* Display documents if showDocuments is true */}
        {showDocuments && (
          <div className={styles.documentList} style={{ border: "1px solid black", padding: "10px" }}>
            <h3>Course Documents:</h3>
            {documents.length > 0 ? (
              documents.map((doc, index) => (
                <div key={index} className={styles.documentItem}>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer" className={styles.documentLink}>
                    {doc.name} {/* Display the document name */}
                  </a>
                </div>
              ))
            ) : (
              <p>No documents available for this course.</p>
            )}
          </div>
        )}

        <h3>Other Videos:</h3>
        <div className={styles.videoList}>
          {videos.map((videoUrl, index) => (
            <div key={index} className={styles.videoWrapper}>
              <video 
                className={styles.sidebarVideo} 
                onClick={() => handleVideoClick(index)} 
                muted 
                controls={false} 
              >
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <p>{videoNames[index]}</p>
            </div>
          ))}
        </div>
      </aside>

      <main className={styles.mainContent}>
        {course ? (
          <>
            <h3>Video:</h3>
            {videos.length > 0 && (
              <div className={styles.videoContainer}>
                <div className={styles.videoWrapper}>
                  <video 
                    className={styles.mainVideo} 
                    controls 
                    onEnded={handleVideoEnded}
                    key={selectedVideoIndex}
                    onContextMenu={preventContextMenu}
                  >
                    <source src={videos[selectedVideoIndex]} type="video/mp4" />
                    Your browser does not support the video tag please contact support.
                  </video>
                  <div className={styles.watermark}>Altafawoq</div>
                </div>
                {videoEnded && (
                  <button onClick={handleNextVideo} className={styles.nextButton}>
                    Next
                  </button>
                )}
              </div>
            )}
          </>
        ) : (
          <p>Course details not found.</p>
        )}
      </main>
    </div>
  );
};

export default CourseDetailPage;