import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, getDocs, setDoc, doc } from "firebase/firestore"; 
import { Container, Row, Col } from "reactstrap"; 
import styles from './LoginEmployee.module.css'; 
import loginImg from "../images/WhatsApp_Image_2024-09-19_at_20.08.01_2e4e8613-removebg-preview.png";

const LoginEmployee = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState(""); 
  const [employees, setEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState(""); 
  const navigate = useNavigate();
  const db = getFirestore(); 

  // Fetch employees from Firestore
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Employees"));
        const employeeData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          email: doc.data().email,
          password: doc.data().password,
          userId: doc.data().userId,
          role: doc.data().role,
        }));
        setEmployees(employeeData);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [db]);

  // Log login attempts with custom document ID
  const logLoginAttempt = async (email, userId, status) => {
    try {
      const timestamp = new Date().toISOString();
      const docId = `${status}-${email}-${timestamp}`; // Custom document ID
      const docRef = doc(db, "LoginAttempts", docId);

      await setDoc(docRef, {
        email: email,
        userId: userId,
        status: status, // granted or denied
        timestamp: timestamp,
      });

      console.log("Login attempt logged successfully with document ID:", docId);
    } catch (error) {
      console.error("Error logging login attempt:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    const employee = employees.find(emp => 
      emp.email === email && 
      emp.password === password && 
      emp.userId.toString() === userId 
    );

    if (employee) {
      // Log successful attempt
      await logLoginAttempt(email, userId, "granted");

      // Redirect based on role
      if (employee.role === "admin") {
        navigate("/dashboard054405910yusuf2404747");
      } else {
        navigate("/user<>104070<>");
      }
    } else {
      // Log failed attempt
      await logLoginAttempt(email, userId, "denied");
      setErrorMessage("Invalid email, password, or user ID for employee.");
    }
  };

  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className={styles.authContainer}>
              <h2>Login Employee</h2>
              <form className={styles.form} onSubmit={handleSubmit}>
                {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
                <div>
                  <label>User ID:</label>
                  <input
                    type="text"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    required
                    placeholder="Enter your user ID"
                  />
                </div>
                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                  />
                </div>
                <div>
                  <label>Password:</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    placeholder="Enter your password"
                  />
                </div>
                <button type="submit" className={styles.loginButton}>Login</button>
              </form>
            </div>
          </Col>
          <Col lg="6" md="6">
            <div className={styles.imageContainer}>
              <img src={loginImg} alt="Employee" className={styles.image} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LoginEmployee;
