import React, { useEffect, useState } from "react";
import { db, auth } from "../FB/firebaseConfig"; // Ensure you have Firebase Auth set up
import { collection, getDocs, query, where, addDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; 
import styles from './Exams.module.css'; // Assuming you have CSS modules set up

const Exams = () => {
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [grade, setGrade] = useState(null);
  const [firstName, setFirstName] = useState(""); // User's first name
  const [lastName, setLastName] = useState(""); // User's last name
  const [email, setEmail] = useState(""); // User's email
  const [submitted, setSubmitted] = useState(false);
  const [enrolledCourses, setEnrolledCourses] = useState([]); // To store enrolled courses

  useEffect(() => {
    const fetchUserEnrollments = async (user) => {
      const enrollmentsRef = collection(db, "enrollments");
      const enrollmentsQuery = query(enrollmentsRef, where("email", "==", user.email));
      const enrollmentsSnapshot = await getDocs(enrollmentsQuery);
      
      if (!enrollmentsSnapshot.empty) {
        const courses = enrollmentsSnapshot.docs.map(doc => doc.data().courseName);
        setEnrolledCourses(courses); // Store enrolled courses
      } else {
        console.log("User is not enrolled in any courses.");
      }
    };

    const fetchExams = async () => {
      const examsRef = collection(db, "exams");
      const examsSnapshot = await getDocs(examsRef);
      const examsData = examsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Filter exams based on enrolled courses
      const filteredExams = examsData.filter(exam => enrolledCourses.includes(exam.courseName));
      setExams(filteredExams);
    };

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email); // Set email directly from Firebase Auth
        await fetchUserEnrollments(user); // Fetch user enrollments
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (enrolledCourses.length > 0) {
      const fetchExams = async () => {
        const examsRef = collection(db, "exams");
        const examsSnapshot = await getDocs(examsRef);
        const examsData = examsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Filter exams based on enrolled courses
        const filteredExams = examsData.filter(exam => enrolledCourses.includes(exam.courseName));
        setExams(filteredExams);
      };

      fetchExams(); // Call fetchExams after enrolled courses are retrieved
    }
  }, [enrolledCourses]);

  const handleStartExam = async (exam) => {
    const gradesRef = collection(db, "userGrades");
    const gradesQuery = query(gradesRef, where("examId", "==", exam.id), where("email", "==", email));
    const gradesSnapshot = await getDocs(gradesQuery);

    if (!gradesSnapshot.empty) {
      setSubmitted(true);
      return; 
    }

    setSelectedExam(exam);
    setUserAnswers({}); 
    setGrade(null); 
    setSubmitted(false); 

    try {
      const questionsRef = collection(db, "questions");
      const questionsQuery = query(questionsRef, where("examId", "==", exam.id));
      const questionsSnapshot = await getDocs(questionsQuery);
      const questionsData = questionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      if (questionsData.length === 0) {
        console.error("No questions found for this exam");
        return; 
      }

      setSelectedExam({ ...exam, questions: questionsData });
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleAnswerChange = (questionId, answer) => {
    setUserAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  const handleSubmitExam = async () => {
    if (!selectedExam || !selectedExam.questions) return;

    let score = 0;
    selectedExam.questions.forEach((question) => {
      const correctOption = question.correctOption;
      if (userAnswers[question.id] === correctOption) {
        score++;
      }
    });

    const totalQuestions = selectedExam.questions.length;
    const finalGrade = (score / totalQuestions) * 100; 
    setGrade(finalGrade); 

    const gradesRef = collection(db, "userGrades");
    await addDoc(gradesRef, {
      firstName, // User's first name
      lastName, // User's last name
      email, 
      examId: selectedExam.id,
      examTitle: selectedExam.title, 
      courseName: selectedExam.courseName, 
      grade: finalGrade,
      timestamp: new Date()
    });

    setSubmitted(true); 
  };

  return (
    <div className={styles.examsContainer}>
      {submitted ? (
        <h4>You have already submitted this exam. Your grade: {grade !== null ? grade.toFixed(2) : "N/A"}%</h4>
      ) : selectedExam ? (
        <div className={styles.examWrapper}>
          <h4>{selectedExam.courseName} - {selectedExam.title}</h4>
          <div className={styles.userInfo}>
            <label>
              First Name:
              <input
                type="text"
                value={firstName}
                readOnly // Make it read-only since it's auto-filled
              />
            </label>
            <label>
              Last Name:
              <input
                type="text"
                value={lastName}
                readOnly // Make it read-only since it's auto-filled
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                value={email}
                readOnly // Make it read-only since it's auto-filled
              />
            </label>
          </div>
          {selectedExam.questions && selectedExam.questions.length > 0 ? (
            selectedExam.questions.map((question) => (
              <div key={question.id}>
                <h5>{question.question}</h5>
                {question.options.map((option, index) => (
                  <label key={index}>
                    <input
                      type="radio"
                      name={question.id}
                      value={option}
                      onChange={() => handleAnswerChange(question.id, option)}
                    />
                    {option}
                  </label>
                ))}
              </div>
            ))
          ) : (
            <p>No questions available for this exam.</p>
          )}
          <button className={styles.submitButton} onClick={handleSubmitExam}>Submit Exam</button>
        </div>
      ) : (
        <ul>
          {exams.map((exam) => (
            <li key={exam.id}>
              <h4>{exam.title} - {exam.courseName}</h4>
              <button onClick={() => handleStartExam(exam)}>Start Exam</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Exams;