import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./components/header/LanguageContext"; // Import the LanguageProvider
import Home from "./pages/Home";
import About from "./components/header/About";
import Layout from './Layout';
import DashboardPage from './ADMIN/DashboardPage.jsx';
import UserProfilePage from "./user/UserProfilePage.jsx"; 
import CourseDetailPage from './user/CourseDetailPage'; 
import HomeworkPage from './user/HomeworkPage'; // Import the HomeworkPage
import LoginEmployee from "./ADMIN/LoginEmployee.jsx";
import ContactUs from './components/header/ContactUs'; 
import PaymentPage from './user/PaymentPage';
import useAnalytics from './useAnalytics'; // Import the analytics hook
import UserDashboard from './ADMIN/UserDashboard.jsx'; // User Dashboard Componen
function App() {
  return (
    <LanguageProvider>
      <Router>
        <AnalyticsTracker /> {/* Call the hook here */}
        <div className="App">
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Home" element={<Home />} />
              <Route path="/About" element={<About />} />
              <Route path="/dashboard054405910yusuf2404747" element={<DashboardPage />} />
              <Route path="//user<>104070<>" element={<UserDashboard />} />
              <Route path="/profile" element={<UserProfilePage />} />
              <Route path="/homework" element={<HomeworkPage />} /> {/* Add this line */}
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/course/:courseId" element={<CourseDetailPage />} />
              <Route path="/loginemployee" element={<LoginEmployee />} />
              <Route path="/contact" element={<ContactUs />} /> 
            </Routes>
          </Layout>
        </div>
      </Router>
    </LanguageProvider>
  );
}

const AnalyticsTracker = () => {
  useAnalytics(); // Call the analytics hook here
  return null; // This component doesn't render anything
};

export default App;