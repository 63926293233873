import React, { useState, useEffect } from 'react';
import { db, storage } from '../FB/firebaseConfig.js'; // Adjust the import as needed
import { collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AddFiles.css'; // Import the CSS file for styles
import loadingIcon from './loading__.gif'; // Adjust the path to your loading icon

const AddFiles = () => {
  const [file, setFile] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  // Fetch courses from Firebase
  useEffect(() => {
    const fetchCourses = async () => {
      const courseCollection = collection(db, 'courses'); // Adjust the collection name if necessary
      const courseSnapshot = await getDocs(courseCollection);
      const courseData = courseSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name, // Assuming each course document has a 'name' field
      }));
      setCourses(courseData);
    };

    fetchCourses();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file || !selectedCourse) return;

    const fileRef = ref(storage, `documents/${selectedCourse}/${file.name}`);
    setIsLoading(true); // Set loading to true

    try {
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      setSuccessMessage(`File uploaded successfully! URL: ${fileURL}`);
    } catch (error) {
      console.error("Error uploading file:", error);
      setSuccessMessage("Error uploading file. Please try again.");
    } finally {
      setIsLoading(false); // Set loading to false
    }
  };

  return (
    <div className="upload-container">
      <h2>رفع الملفات</h2>
      <div>
        <label htmlFor="courseSelect">الرجاء اختيار الكورس</label>
        <select 
          id="courseSelect" 
          value={selectedCourse} 
          onChange={(e) => setSelectedCourse(e.target.value)} 
          required
        >
          <option value="">Select Course</option>
          {courses.map(course => (
            <option key={course.id} value={course.name}>
              {course.name}
            </option>
          ))}
        </select>
      </div>
      <form onSubmit={handleUpload} className="upload-form">
        <input type="file" onChange={handleFileChange} required />
        <button type="submit" className="upload-button">Upload</button>
      </form>
      {isLoading && <img src={loadingIcon} alt="Loading..." className="loading-icon" />}
      {successMessage && <div>{successMessage}</div>}
    </div>
  );
};

export default AddFiles;