import React, { useState, useEffect } from 'react';
import { db } from '../FB/firebaseConfig'; // Adjust the import as needed
import { addDoc, collection, getDocs } from 'firebase/firestore';
import './SendMessage.css'; // Import the CSS file
import loadingGif from './loading__.gif'; // Adjust the path as necessary

const SendMessage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  useEffect(() => {
    const fetchUsers = async () => {
      const userCollection = collection(db, 'users');
      const userSnapshot = await getDocs(userCollection);
      const userData = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(userData);
      setFilteredUsers(userData); // Set initial filtered users
    };

    fetchUsers();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = users.filter(user => 
      user.firstName.toLowerCase().includes(term) ||
      user.lastName.toLowerCase().includes(term) ||
      user.email.toLowerCase().includes(term)
    );
    setFilteredUsers(filtered);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');

    try {
      const messagesRef = collection(db, 'userMessages');

      if (selectedUserEmail === 'all') {
        // Send message to all users
        const batch = db.batch(); // Use batch for multiple writes
        users.forEach(user => {
          const userMessageRef = collection(db, 'userMessages');
          const newMessageRef = addDoc(userMessageRef, {
            email: user.email,
            message,
            timestamp: new Date(),
          });
          batch.set(newMessageRef, { email: user.email, message, timestamp: new Date() });
        });
        await batch.commit();
      } else {
        // Send message to selected user
        await addDoc(messagesRef, {
          email: selectedUserEmail,
          message,
          timestamp: new Date(),
        });
      }

      setSuccessMessage('Message sent successfully!');
      setSelectedUserEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="send-message-container">
      <h2>Send Message to User</h2>
      <input
        type="text"
        placeholder="Search by name or email..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-input"
      />
      <form onSubmit={handleSendMessage}>
        <div className="form-group">
          <label>Select User:</label>
          <select
            value={selectedUserEmail}
            onChange={(e) => setSelectedUserEmail(e.target.value)}
            required
          >
            <option value="">Select User</option>
            <option value="all">All Users</option>
            {filteredUsers.map(user => (
              <option key={user.id} value={user.email}>
                {user.firstName} {user.lastName} ({user.email})
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Message:</label>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </form>
      {loading && (
        <div className="loading-container">
          <img src={loadingGif} alt="Loading..." />
        </div>
      )}
      {successMessage && <div className="success-message">{successMessage}</div>}
    </div>
  );
};

export default SendMessage;